import { useState, useRef, useEffect } from 'react';
import IconButton from "@mui/material/IconButton";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';

export default function GameMp3Player({ mp3Url, fileName, setAudioName, startTimer }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);

    useEffect(() => {
        audioRef.current = new Audio(mp3Url); // 创建新的 Audio 对象
        audioRef.current.addEventListener('ended', handleAudioEnd); // 添加监听器

        return () => {
            if (audioRef.current) {
                audioRef.current.pause(); // 暂停音频
                audioRef.current.removeEventListener('ended', handleAudioEnd); // 移除监听器
            }
        };
    }, [mp3Url]);

    const playAudio = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
                setAudioName(fileName);
                startTimer();  // 调用 startTimer() 只有在播放新的音频时
            }
            setIsPlaying(!isPlaying);
        }
    };

    const handleAudioEnd = () => {
        setIsPlaying(false);
    };
    return (
        <IconButton
            style={{ color: 'black' }}
            aria-label="Play audio"
            onClick={playAudio}
        >
            {isPlaying ? (
                <PauseCircleOutlineIcon fontSize="large" />
            ) : (
                <PlayCircleOutlineIcon fontSize="large"/>
            )}
        </IconButton>
    );
}
