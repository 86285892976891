import * as React from 'react';
import Box from '@mui/material/Box';
import { useState,useRef,useEffect} from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { isMobile } from 'react-device-detect';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import GameMp3Player from './GameMp3Player';
import { apiUrl } from '../apiUrl/ApiUrl';
import { token } from '../token/Token'




export default function StudentAnswerView() {
    const [windowDimensions, setWindowDimensions] = useState({
        width: typeof window !== 'undefined' ? window.innerWidth : 0,
        height: typeof window !== 'undefined' ? window.innerHeight : 0,
    });
    const [testInfo,setTestInfo] = useState(null)
    const [types,setTypes] = React.useState([])
    const [questionNum,setQuestionNum] = React.useState(0)
    const [audioName, setAudioName] = React.useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [answer, setAnswer] = React.useState('');
    const [seconds, setSeconds] = useState(0);
    const [isActive, setIsActive] = useState(false);
    const [showTipOne, setShowTipOne] = useState(false);
    const [showTipTwo, setShowTipTwo] = useState(false);
    const [showTipButtuon, setShowTipButtuon] = useState(false);
    const [showTipButtuon2, setShowTipButtuon2] = useState(false);
    const [errorCount,setErrorCount] = useState(0);
    const [accountLevel,setAccountLevel] = useState(null);

    const [jumpQum, setJumpQum] = React.useState(1);
    

    let timer = null;

    const startTimer = () => {
        setIsActive(true);
    };
    const pauseTimer = () => {
        setIsActive(false);
    };
    useEffect(() => {
        if (isActive) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          timer = setInterval(() => {
            setSeconds(prevSeconds => prevSeconds + 1);
          }, 1000);
        } else {
          clearInterval(timer);
        }
    
        return () => {
          clearInterval(timer);
        };
      }, [isActive]);
      useEffect(() => {
        if (errorCount >= 4) {
            setShowTipButtuon(true)
        } 
        if(errorCount >=10)  {
            setShowTipButtuon2(true)
        }
      }, [errorCount]);
      useEffect(       
        () => {
            const level = parseInt(sessionStorage.getItem('level'), 10);
            setAccountLevel(level);
          }
        , []);

      const verify = async () => {            
          const answerLog = {
            account: sessionStorage.getItem('studentAccount'),
            name: sessionStorage.getItem('userName'),
            level: testInfo.studyLevel.toString(),
            answer,
            timeCost: seconds,  // 你可以根据需要设置这个值
            questionType: testInfo.typeName,
            questionNum,  // 你可以根据需要设置这个值
            id: sessionStorage.getItem('id'),
            model:'phone'
          };
          pauseTimer();
          try {       
          const response = await axios.post(`${apiUrl}/answerLog/verify`, answerLog, {
            headers: {
              'X-Ap-Token': `${token}`
            }
          });
          console.log(response)
          if(response.status === 200) {
            setTestInfo(response.data.SentenceManageDTO)
            if (!response.data.isPass) {
                setErrorCount(errorCount+1);
                alert('加油 很接近答案了 再試試')
              }
          }

        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

    const fetchTestData = async (typeId,isPlus) => {
        const userId = parseInt(sessionStorage.getItem('id'),10);
        let qid = questionNum;
        if(isPlus && isPlus !== null) {
            if(qid+1 <= testInfo.maxQum) {            
                qid +=1;
            }else{
                alert('已到達最大題數');
                return;
            }
        }
        if(!isPlus && isPlus !== null) {
            if(qid-1 > 0 ) {
                qid -=1;
            }else{
                alert('已到達最小題數');
                return;
            }
        }
        
        try {
            if(questionNum === 0) {
                const response = await axios.get(`${apiUrl}/sentence/phonetest?typeId=${typeId}&userId=${userId}`, {
                    headers: {
                        'X-Ap-Token':`${token}`
                    }
                    });
                    // 檢查響應的結果，並設置到 state
                    if (response.status === 200) {
                    setTestInfo(response.data.SentenceManageDTO)
                    const QId = parseInt(response.data.SentenceManageDTO.questionNum,10);
                    setQuestionNum(QId) 
                    setAnswer(response.data.SentenceManageDTO.answerLog)
                    setErrorCount(0)
                    setShowTipButtuon(false)
                    setShowTipButtuon2(false)
                    setShowTipOne(false)
                    setShowTipTwo(false)
                    }
            }else {
                const response = await axios.get(`${apiUrl}/sentence/phonetest?typeId=${typeId}&questionNum=${qid}&userId=${userId}`, {
                    headers: {
                        'X-Ap-Token':`${token}`
                    }
                    });
                    // 檢查響應的結果，並設置到 state
                    if (response.status === 200) {
                    setTestInfo(response.data.SentenceManageDTO)
                    const QId = parseInt(response.data.SentenceManageDTO.questionNum,10);
                    setQuestionNum(QId) 
                    setAnswer(response.data.SentenceManageDTO.answerLog)
                    setErrorCount(0)
                    setShowTipButtuon(false)
                    setShowTipButtuon2(false)
                    setShowTipOne(false)
                    setShowTipTwo(false)
                    }
            }          
        } catch (error) {
            console.error('Error fetching data:', error);          
        }
    };

    const handleCheckboxChange = async () => {      
        try {       
            const QId = parseInt(testInfo.questionNum,10);
            const userId = parseInt(sessionStorage.getItem('id'),10);         
            const response = await axios.get(`${apiUrl}/sentence/checkQum?studentId=${userId}&questionId=${QId}`, {
              headers: {
                'X-Ap-Token':`${token}`
              }
            });
            // 檢查響應的結果，並設置到 state
            if (response.status === 200) {
                setIsChecked(response.data) 
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
    };

    const fetchJumpData = async (typeId) => {
        const userId = parseInt(sessionStorage.getItem('id'),10);
        const qid = jumpQum;      
        try {
           
            const response = await axios.get(`${apiUrl}/sentence/phonetest?typeId=${typeId}&questionNum=${qid}&userId=${userId}`, {
                headers: {
                    'X-Ap-Token':`${token}`
                }
                });
                // 檢查響應的結果，並設置到 state
                if (response.status === 200) {
                setTestInfo(response.data.SentenceManageDTO)
                const QId = parseInt(response.data.SentenceManageDTO.questionNum,10);
                setQuestionNum(QId) 
                setAnswer(response.data.SentenceManageDTO.answerLog)
                setErrorCount(0)
                setShowTipButtuon(false)
                setShowTipButtuon2(false)
                setShowTipOne(false)
                setShowTipTwo(false)
                }
                    
        } catch (error) {
            console.error('Error fetching data:', error);          
        }
    };

    useEffect(() => {
        if(testInfo !== null) {
        const QId = parseInt(testInfo.questionNum,10);
        const userId = parseInt(sessionStorage.getItem('id'),10);
        setQuestionNum(QId) ;

        const fetchCheckData = async () => {
            try {       
              const response = await axios.get(`${apiUrl}/sentence/checkinfo?studentId=${userId}&questionId=${QId}`, {
                headers: {
                  'X-Ap-Token':`${token}`
                }
              });
              // 檢查響應的結果，並設置到 state
              if (response.status === 200) {
                  setIsChecked(response.data) 
              }
            } catch (error) {
              console.error('Error fetching data:', error);
            }
        };
        fetchCheckData();

        }
    }, [testInfo]);


      useEffect(() => {
        // 定義非同步 function 來獲取數據
        const fetchData = async () => {
          try {       
            const response = await axios.get(`${apiUrl}/sentence/type`, {
              headers: {
                'X-Ap-Token':`${token}`
              }
            });
            // 檢查響應的結果，並設置到 state
            if (response.status === 200) {
                setTypes(response.data.List) 
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        fetchData();
      }, []); 
      
  return (
    <>
    {testInfo !== null &&(
    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%',position: 'relative' }}>
        <Box sx={{width: '100%', height:`${windowDimensions.height+150}px`,backgroundColor:'white',minHeight:'800px'}}>
            <Box sx={{ width: '100%', height: `${windowDimensions.height*0.5+70}px`,minHeight:'500px',marginBottom: !isMobile ? '3%' : '0%',marginTop: !isMobile ? '2%' : '0%'  }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2} 
                        alignItems="center" // 垂直居中
                        justifyContent="center" // 水平居中
                    >
                        <Grid item xs={12}/>
                        <Grid item xs={12}/>
                        <Grid item xs={12}/>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'black' }}>
                            <Button
                                onClick={() => {
                                    setTestInfo(null);
                                }}
                                sx={{ 
                                    // 这可以使按钮的背景、边框等不可见，让按钮看起来像一个普通的文本
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none',
                                    '&:hover': {
                                        // 鼠标悬停时的样式，如果你不想有任何变化可以不设置或设置为透明背景和无阴影
                                        backgroundColor: 'transparent',
                                        boxShadow: 'none',
                                    },
                                }}
                            >
                                <Typography variant="h3" gutterBottom>
                                    {testInfo.typeName}
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={12}/>
                        <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>                   
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{ display: 'flex',color:'black', 
                                alignItems: 'center', justifyContent: 'center' }}>
                                    題號:{testInfo.questionNum}
                                </Grid>
                                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center',color:'black',  
                                justifyContent: 'center' }}>
                                    {testInfo.questionChineseName}
                                </Grid>
                                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center',color:'black', 
                                 justifyContent: 'center' }}>
                                    {testInfo.mp3FileName}
                                </Grid>
                            </Grid>                        
                        </Grid>
                        
                        <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <GameMp3Player mp3Url={`${apiUrl}${testInfo.mp3FileUrl}`} fileName={`${testInfo.mp3FileName}`} 
                            setAudioName={setAudioName} startTimer={startTimer}/>                           
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',color:'black' }}>
                            {testInfo.isPass === -1 ?"未作答":null}
                            {testInfo.isPass === 0 ?"未通過":null}
                            {testInfo.isPass === 1?"已通過":null}
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',color:'black',marginLeft:'15px',marginRight:'10px',overflowY: 'auto'}}>
                            {testInfo.isPass === 1?testInfo.questionSentenceChinese:null}
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',color:'black',marginLeft:'15px',marginRight:'10px',overflowY: 'auto'}}>
                            {testInfo.isPass === 1?testInfo.questionAnswer:null}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box sx={{width: '100%',minHeight:'300px', height:`${windowDimensions.height*0.5+30}px`}}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>     
                            <TextField
                            id="outlined-multiline-flexible"
                            placeholder='作答區'
                            multiline
                            color="warning" 
                            rows={5}
                            value={answer} 
                            style={{backgroundColor:'white',borderRadius: '8px',width:'90%',color:'black' }}
                            onChange={(e)=>setAnswer(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: isMobile ? 'flex-start' : 'center',color:'black',marginLeft:'15px',marginRight:'10px' }}>
                            {showTipButtuon?
                            <>
                            <Button variant="contained" size="small" style={{background:'lightblue',color:'black'}} onClick={()=>setShowTipOne(true)}>
                                提示1
                            </Button>
                                {showTipOne?
                                <Alert severity="info" style={{background:'transparent',color:'black'}}>{testInfo.questionHint}</Alert>
                                :null}
                            </>:null}
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: isMobile ? 'flex-start' : 'center',color:'black',marginLeft:'15px',marginRight:'10px' }}>
                            {showTipButtuon2?
                            <>
                            <Button variant="contained" size="small" style={{background:'lightblue',color:'black'}} onClick={()=>setShowTipTwo(true)}>
                                提示2
                            </Button>
                                {showTipTwo?
                                <Alert severity="info" style={{background:'transparent',color:'black'}}>{testInfo.questionHint2}</Alert>
                                :null}
                            </>:null}
                        </Grid>
                        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Button variant="contained" size='medium' style={{backgroundColor:'black'}}
                            onClick={()=>fetchTestData(testInfo.questionTypeId,false)}>                                
                                上一題
                            </Button>
                        </Grid>
                        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Button variant="contained" size='large' endIcon={<SendIcon />} style={{backgroundColor:'orange'}}
                            onClick={() => verify()}>
                                送出答案
                            </Button>
                        </Grid>
                        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Button variant="contained" size='medium' style={{backgroundColor:'black'}}
                            onClick={()=>fetchTestData(testInfo.questionTypeId,true)}>
                                下一題
                            </Button>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>                    
                            <FormControlLabel control={<Checkbox checked={isChecked} onChange={handleCheckboxChange}/>} label="勾選" />
                            <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
                                <InputLabel id="demo-simple-select-label">題號</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={jumpQum}
                                    label="Age"
                                    onChange={(e)=>setJumpQum(e.target.value)}
                                >
                                    {Array.from({ length: testInfo.maxQum}, (_, index) => (
                                    <MenuItem value={index + 1} key={index + 1}>
                                        {index + 1}
                                    </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Button variant="contained" size='medium' style={{backgroundColor:'black'}}
                            onClick={()=>fetchJumpData(testInfo.questionTypeId)}>                                
                                跳題
                            </Button>                          
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    </Box>)}
    {testInfo === null && (
        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', position: 'relative' }}>
            <Box sx={{ width: '100%', height: `${windowDimensions.height}px`, backgroundColor: 'white' }}>
                <Box sx={{ width: '100%', height: `${windowDimensions.height / 2 - 50}px` }}>
                    <Box sx={{ flexGrow: 1,padding:'30px' }}>
                        <Grid container spacing={2}
                            alignItems="center" // 垂直居中
                            justifyContent="center" // 水平居中
                            sx={{ flexWrap: 'wrap' }} // 自动换行
                        >
                            {types.sort((a, b) => a.studyLevel - b.studyLevel).map((type, index) => (
                                type.studyLevel <= accountLevel ? (
                                    <Button key={index}
                                        style={{
                                            color: 'black',
                                            borderColor: 'black', // 白色边框
                                            margin: '10px', // 距离四周有10px
                                        }}
                                        variant="outlined"
                                        size='large'
                                        onClick={()=>fetchTestData(type.id,null)}
                                    >
                                        {type.type}
                                    </Button>
                                ) : null
                            ))}
                            <Grid item xs={12}/>
                            <Grid item xs={12}/>
                            <Grid item xs={12}/>
                            <Grid item xs={12}/>
                            <Grid item xs={12}/>
                            <Grid item xs={12}/>
                            <Grid item xs={12}/>
                            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>   
                                {!isMobile ?                                 
                                <Typography variant="h1" gutterBottom style={{color:'orange'}}>
                                    請選擇聽力類型
                                </Typography>:null}  
                            </Grid>
                        </Grid>                       
                    </Box>
                </Box>
                <Box sx={{ width: '100%', height: `${windowDimensions.height / 2 + 50}px` }} />
            </Box>
        </Box>
    )}
    </>
  );
}