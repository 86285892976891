import * as React from 'react';
import { useState,useRef,useEffect} from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import axios from 'axios';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { isMobile,isTablet } from 'react-device-detect';
import { useSpring, animated } from '@react-spring/web'
import Button from '@mui/material/Button';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import SportsMmaIcon from '@mui/icons-material/SportsMma';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AnswerDrag from './AnswerDrag';
import AnswerMatch from './AnswerMatch'
import GameMp3Player from './GameMp3Player';
import Combo from '../animate/Combo';
import Error from '../animate/Error';
import Correct from '../animate/Correct';
import ComboCom from '../animateCom/ComboCom'
import ErrorCom from '../animateCom/ErrorCom';
import CorrectCom from '../animateCom/CorrectCom';
import GameOverView from '../animate/GameOverView';
import GameOverViewCom from '../animateCom/GameOverViewCom';
import GameAllPassView from '../animate/GameAllPassView';
import { apiUrl } from '../apiUrl/ApiUrl';
import { token } from '../token/Token'

// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
//   });

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: '#ff6d75',
    },
    '& .MuiRating-iconHover': {
      color: '#ff3d47',
    },
  });

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

export default function StudentGame() {
    const maxLevel = 33;  // 小關卡調整從這邊
    const [maxPoint, setMaxPoint] = useState(0);
    const [bigLevel,setbigLevel] = useState(1);
    const [introdeuce,setIntroduce] = useState(false);
    const [gameWords,setGameWords] = useState([])
    const [boxWords,setBoxWords] = useState([])
    const [gameLevel,setGameLevel] = useState('1')
    const [userId, setUserId] = useState('')
    const [isGameOver,setGameOver] = useState(null);
    const [isAllPass,setIsAllPass] = useState(false);
    const [extraWord,setExtraWord] = useState(null);
    const [isPass,setIsPass] = useState(false);
    const [passCount,setPassCount] = useState(0);
    const [buttonCanUse,setButtonCanUse] = useState(false);



    const [wordsCount,setWordsCount] = useState(0);
    const shuffleArray = (array) => array.sort(() => Math.random() - 0.5);
    const navigate = useNavigate();

    useEffect(() => {
      const token = sessionStorage.getItem('studentAccount');
      if (!token) {
        navigate('/login');
      }
    }, [navigate]);

    useEffect(() => {
        // Add event listener for beforeunload event
        const handleBeforeUnload = (e) => {
          // Show an alert when the page is about to be reloaded
          e.preventDefault();
          e.returnValue = ''; // Required for Chrome
          sessionStorage.setItem('gameLevel','1');
          handleClearError();
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        // Cleanup the event listener when the component unmounts
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, []);
    const startTimer = () => {
        setButtonCanUse(true);
    };
    useEffect(() => {
        setIntroduce(true);
    }, []);

    const setAnswerAndButton = (word) => {
        setGameAns(word);
        setButtonCanUse(false);
    }

    const fetchDataAndUpdate = async (level) => {
        const smallLevel = parseInt(sessionStorage.getItem('gameLevel'),10);
        setIsPass(false)
        if(!isGameOver) {
            if (sessionStorage.getItem('gameLevel') === null) {
                sessionStorage.setItem('gameLevel', '1');
                setGameLevel(sessionStorage.getItem('gameLevel'));
                setUserId(sessionStorage.getItem('id'));
            }
            if(smallLevel > maxLevel)
            {
                sessionStorage.setItem('gameLevel', '1');
                setGameLevel(sessionStorage.getItem('gameLevel'));
            }
            if(level === null || level === undefined) {
                try {
                    const response = await axios.get(`${apiUrl}/game/words?gamer=${sessionStorage.getItem('id')}&level=${bigLevel}`, {
                        headers: {
                            'X-Ap-Token': `${token}`
                        }
                    });
            
                    if (response.status === 200) {
                        setGameWords(response.data.Words);
                        setBoxWords(shuffleArray([...response.data.Words,response.data.ExtraWord]));
                        setWordsCount(response.data.Words.length);
                        setExtraWord(response.data.ExtraWord);
                        handleClearError();
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }else {
                try {
                    const response = await axios.get(`${apiUrl}/game/words?gamer=${sessionStorage.getItem('id')}&level=${bigLevel}`, {
                        headers: {
                            'X-Ap-Token': `${token}`
                        }
                    });
            
                    if (response.status === 200) {
                        setGameWords(response.data.Words);
                        setBoxWords(shuffleArray([...response.data.Words,response.data.ExtraWord]));
                        setWordsCount(response.data.Words.length);
                        setExtraWord(response.data.ExtraWord);
                        handleClearError();
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
           
        }
    };
    useEffect(() => {
        if(isGameOver === false) {
            fetchDataAndUpdate();
            fetchScore();
        }else {
            sessionStorage.setItem('gameLevel','1');
            setScore(0);
            setMatchItems([]);
            setPassCount(0);
            setTotalSeconds(15)
            setGameLevel('1');
            setbigLevel('1');
            fetchScore();      
            clearWordsLog();     
        }
    }, [isGameOver]); // 将 isGameOver 添加到依赖数组中，确保在其变化时执行

    const fetchScore = async () => {
        try {       
          const Id = parseInt(sessionStorage.getItem('id'),10);
          const response = await axios.get(`${apiUrl}/answerLog/maxpoint?studentId=${Id}`,{
            headers: {
              'X-Ap-Token': token // Add Token to headers
            }
          });
          // 檢查響應的結果，並設置到 state
          if (response.status === 200) {
            setMaxPoint(response.data.MaxPoint || 0);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };

    const clearWordsLog = async () => {
        try {       
          const response = await axios.get(`${apiUrl}/game/gameoverclear?gamer=${sessionStorage.getItem('id')}`,{
            headers: {
              'X-Ap-Token': token // Add Token to headers
            }
          });
          // 檢查響應的結果，並設置到 state
          if (response.status === 200) {
            console.log('清除單字LOG成功')
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };

    const updateMaxScore = async () => {
        try {       
          const Id = parseInt(sessionStorage.getItem('id'),10);
          const response = await axios.get(`${apiUrl}/answerLog/addpoint?studentId=${Id}&point=${score}`);
          // 檢查響應的結果，並設置到 state
          if (response.status === 200) {
            console.log('更新成功')
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };
    // 初次渲染时也执行
    useEffect(() => {
        sessionStorage.setItem('gameLevel','1');
        handleClearError();
        fetchScore();
        fetchDataAndUpdate();
        setScore(0);
    }, []);
    const changeHardLevel = (value) => {
        setbigLevel(value);
      };
    
      useEffect(() => {
        // 在这里呼叫你的 API
        fetchDataAndUpdate();
      }, [bigLevel]);
    
    const [windowDimensions, setWindowDimensions] = useState({
        width: typeof window !== 'undefined' ? window.innerWidth : 0,
        height: typeof window !== 'undefined' ? window.innerHeight : 0,
      });
    const [mobileGameStart,setMobileGameStart] = useState(false);
    const [timer, setTimer] = useState(0);
    const [totalSeconds , setTotalSeconds] = useState(15);
    const [audioName, setAudioName] = useState('');
    const [gameAns, setGameAns] = useState('');
    const [life, setLife] = useState(3);
    const [score,setScore] = useState(0);
    const [combo,setCombo] = useState(0);
    const [showCombo,setShowCombo] = useState(false);
    const [showError,setshowError] = useState(false);
    const [showCorrect,setshowCorrect] = useState(false);
    const [matchItems,setMatchItems] = useState([]);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (audioName && gameAns) {
          if(audioName === gameAns) {
            setScore(score+30);
            setCombo(combo+1);
            onCorrectAnswer();
            setAudioName(null);setGameAns(null);
            setPassCount(passCount+1);
            setMatchItems([...matchItems, audioName]);
            if(!isPass) {
                setshowCorrect(true);
            }          
          }else {
            setLife(life-1)
            handleErrorPost(audioName);
            setCombo(0);
            setAudioName(null);
            setGameAns(null);
            setshowError(true)
          }
        }
      }, [audioName, gameAns]);

      const handleErrorPost = async (errorWord) => {
        try {
            const errorLogData = {
            errWord: errorWord,
            level: parseInt(bigLevel, 10), // Convert gameLevel to number
            id: sessionStorage.getItem('id')
            };
            const response = await axios.post(`${apiUrl}/game/errorlog`, errorLogData, {
                headers: {
                  'X-Ap-Token': token // Add Token to headers
                }
              });
    
          if (response.status === 200) {
            console.log('POST request was successful:', response.data);
          } else {
            console.log('POST request failed with status:', response.status);
          }
        } catch (error) {
          console.error('Error sending POST request:', error);
        }
    };
    
    const handleClearError= async () => {
        try {
            const response = await axios.get(`${apiUrl}/game/errclear?gamer=${sessionStorage.getItem('id')}`,{
                headers: {
                  'X-Ap-Token': token // Add Token to headers
                }
              });
    
          if (response.status === 200) {
            console.log('get request was successful:', response.data);
          } else {
            console.log('get request failed with status:', response.status);
          }
        } catch (error) {
          console.error('Error sending get request:', error);
        }
    };

      // eslint-disable-next-line consistent-return
      useEffect(() => {
        if (showCorrect) {
            const corout = setTimeout(() => {
                setshowCorrect(false);
            }, 1000);
    
            return () => {
                clearTimeout(corout);
            };
        }
      }, [showCorrect]);

      useEffect(() => {
        if (passCount%wordsCount === 0 && passCount > 0) {
            setIsPass(true);
            setPassCount(0);
        }
      }, [passCount]);

      useEffect(() => {
        if (bigLevel === '11') {
            setIsAllPass(true);
        }
      }, [bigLevel]);

      useEffect(() => {
        if(isPass) {
            const currentGameLevel = parseInt(sessionStorage.getItem('gameLevel'), 10); // 将获取的字符串转换为数字
            sessionStorage.setItem('gameLevel',(currentGameLevel+1).toString());
            setGameLevel(currentGameLevel+1); 
            fetchDataAndUpdate(gameLevel);  
            setMatchItems([]);       
            if ((currentGameLevel + 1) > maxLevel ) { // 要加一才是最小關卡 有兩個地方要改 上面也要
                const newBigLevel = parseInt(bigLevel, 10) + 1;  // 转换为数字并加 1
                setbigLevel(newBigLevel.toString());  // 转回为字符串并设置
                setGameLevel('1');
            }     
        }

      }, [isPass]);

      // eslint-disable-next-line consistent-return
      useEffect(() => {
        const errorout = setTimeout(() => {
            setshowError(false);
        }, 1000);

        return () => {
            clearTimeout(errorout);
        };
      }, [showError]);

      useEffect(() => {
        if(life === 0 || life < 0) {
            setGameOver(true);
            setGameLevel('1');
            setShowCombo(false);
            setIsPass(false);
            setCombo(0);
            updateMaxScore();
            fetchScore();
            clearWordsLog();
        }
      }, [life]);
      
      // eslint-disable-next-line consistent-return
      useEffect(() => {
        if (combo % 3 === 0 && combo !== 0) {
            setShowCombo(true);
            setScore(score+30);
          const timeout = setTimeout(() => {
            setShowCombo(false);
          }, 1000);
    
          return () => {
            clearTimeout(timeout);
          };
        }
      }, [combo]);

    useEffect(() => {
        let interval;
    
        if (mobileGameStart) {
            setIsPass(false);
          interval = setInterval(() => {
            setTimer(prevTimer => prevTimer + 1);
          }, 1000);
        }
    
        return () => {
          clearInterval(interval);
        };
      }, [mobileGameStart]);

      useEffect(() => {
        let intervalId;
        
        if (mobileGameStart && !isGameOver) {
          intervalId = setInterval(() => {
            setTotalSeconds((prevSeconds) => {
              const nextSeconds = prevSeconds - 1;
              if (nextSeconds <= 0) {
                setIsPass(false);
                clearInterval(intervalId);
                setGameOver(true);
                setMobileGameStart(false);
                setTotalSeconds(15);
                updateMaxScore();
                return 0;
              }
              return nextSeconds;
            });
          }, 1000);
        }
      
        return () => {
          if (intervalId) clearInterval(intervalId);
        };
      }, [mobileGameStart, totalSeconds]); // 添加 totalSeconds 到依赖数组中
      

      const onCorrectAnswer = () => {
        setTotalSeconds(prevSeconds => prevSeconds + 2);
      }

    
  return (
    <>
        { !isGameOver && !isAllPass ? (
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%',position: 'relative'}}>
                <Box sx={{width: '100%', height:`${windowDimensions.height}px`,backgroundColor:'white'}}>
                    <Box sx={{width: '100%', height:`${windowDimensions.height/2}px`}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{ 
                                display: 'flex', 
                                justifyContent: 'center', 
                                alignItems: 'center',
                                padding: '20px', 
                                height: 'auto', 
                                color: 'black', 
                                fontSize: '32px' // 或者使用像 '24px' 这样的具体值
                            }}>
                                {mobileGameStart === true?
                                <>
                                    <Typography component="legend" style={{ display: 'block',marginRight:'10px'}}>
                                        Level {bigLevel}-{gameLevel}
                                    </Typography>
                                    <Typography component="legend" style={{ display: 'block'}}>
                                        {sessionStorage.getItem('userName')}生命值:
                                    </Typography>
                                    <StyledRating
                                        name="customized-color"
                                        value={life}
                                        max={3}
                                        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
                                        precision={0.5}
                                        icon={<FavoriteIcon fontSize="inherit" />}
                                        emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
                                    /> 
                                </>: 
                                <>           
                                        <Typography variant="h4" gutterBottom>
                                                準備作答
                                        </Typography>                  
                                </>
                                  }
                            </Grid>
                            {mobileGameStart === false && (
                                    <Grid item xs={12} style={{ 
                                        display: 'flex', 
                                        justifyContent: 'center', 
                                        alignItems: 'center',
                                    }}>
                                    <FormControl>
                                        <InputLabel id="demo-simple-select-label" style={{color:'black'}}>HardLevel</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={bigLevel}
                                            label="HardLevel"
                                            style={{width:'100px',color:'black'}}
                                            onChange={(e)=>changeHardLevel(e.target.value)}
                                        >
                                            <MenuItem value='1'>1</MenuItem>
                                            <MenuItem value='2'>2</MenuItem>
                                            <MenuItem value='3'>3</MenuItem>
                                            <MenuItem value='4'>4</MenuItem>
                                            <MenuItem value='5' >5</MenuItem>
                                            <MenuItem value='6' >6</MenuItem>
                                            <MenuItem value='7' >7</MenuItem>
                                            <MenuItem value='8'>8</MenuItem>
                                            <MenuItem value='9'>9</MenuItem>
                                            <MenuItem value='10' >10</MenuItem>
                                        </Select>
                                    </FormControl>
                                    </Grid>
                            )}
                            {mobileGameStart === true && (
                                <>
                                    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',height:'60px'  }}>
                                        {showCombo === true && isMobile === true && (
                                            <Combo />
                                        )}  
                                        {showCombo === true && isMobile === false && (
                                            <ComboCom />
                                        )} 
                                    </Grid>
                                    <Grid item xs={8} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',height:'60px' }}>
                                        <Typography variant="h6" gutterBottom style={{ color: 'black'}}>
                                            最高紀錄: {maxPoint} 剩餘秒數:{totalSeconds}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',height:'60px'  }}>
                                        {showCorrect === true && isMobile === true &&(
                                            <Correct/>
                                        )}
                                        {showCorrect === true && isMobile === false &&(
                                            <CorrectCom/>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography variant="h6" gutterBottom style={{ color: 'black'}}>
                                            SCORE:   {score} 
                                        </Typography>
                                    </Grid>
                                </>
                            )}
                                {gameWords.map((item, index) => (
                                    mobileGameStart ? (  
                                        <Grid key={item.id} item xs={4} style={{ color: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <Grid container direction="column" alignItems="center" style={{ color: 'black' }}>
                                            <Grid item xs={3}>
                                            <div>題目{index+1}</div>
                                            </Grid>
                                            <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                {!matchItems.includes(item.word) ? (
                                                    <GameMp3Player mp3Url={apiUrl + item.mp3Url} fileName={item.word} setAudioName={setAudioName} startTimer={startTimer}/>
                                                ) : (
                                                    <AnswerMatch/> // 替代的組件，當條件不滿足時渲染
                                                )}
                                            </Grid>
                                        </Grid>
                                        </Grid>
                                    ) : null
                                ))}
                        </Grid>
                    </Box>
                    <Box sx={{width: '100%', height:`${windowDimensions.height/2+50}px`}}>
                        <Grid container>
                            {boxWords.map((item, index) => (
                            <Grid item xs={4} key={item.id}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                                <img
                                    src={mobileGameStart === true ? '/images/nobox.png' : '/images/box.png'}
                                    alt={`fire`}
                                    style={{ 
                                        width: isMobile ? '80%' : '20%',  // 根據條件變更寬度
                                        height: 'auto' 
                                    }}
                                />
                                {mobileGameStart === true && (
                                    <Button
                                    variant="outlined"
                                    color="primary"
                                    disabled={!buttonCanUse}
                                    style={{
                                        position: 'absolute', 
                                        top: '50%',  
                                        left: '50%', 
                                        transform: 'translate(-50%, -50%)', 
                                        zIndex: 1,  
                                        color: 'black',
                                        fontSize: '16px',
                                        textTransform:item.word === item.word.toLowerCase() ? 'lowercase' : 'none',
                                        width: isMobile ? '80%' : '30%',
                                    }}
                                    onClick={() => setAnswerAndButton(item.word)}
                                    >
                                    {item.word} <br/>
                                    {item.wordChinese}
                                    </Button>
                                )}
                                </div>
                            </Grid>
                            ))}                       
                        </Grid>
                    </Box>
                </Box>
                {mobileGameStart === false && (
                <Button 
                    variant="outlined"                  
                    style={{
                        position: 'absolute',  // 绝对定位
                        top: '50%',  // 从顶部偏移50%
                        left: '50%',  // 从左侧偏移50%
                        transform: 'translate(-50%, -50%)',  // 使用 transform 进行微调
                        zIndex: 2 , // 设置 z-index 以确保按钮出现在其他元素之上
                        fontSize:'24px',
                        color:'black',
                        border: '2px solid black',  // 设置边框
                        borderWidth: '2px',         // 设置边框宽度
                        borderColor: 'black',      // 设置边框颜色
                        }}
                    endIcon={<SportsMmaIcon style={{ fontSize: '40px' }}/>}
                    onClick={()=>setMobileGameStart(true)}
                >
                    Game Start
                </Button>
                )} 
                {showError === true && isMobile === true &&(
                    <Error/>
                )}
  
                {showError === true && isMobile === false &&(
                    <ErrorCom/>
                )}

                <Dialog
                    
                    open={introdeuce}
                    onClose={()=>setIntroduce(false)}
                    // TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                        edge="start"
                        color="inherit"
                        onClick={()=>setIntroduce(false)}
                        aria-label="close"
                        >
                        <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        遊戲規則說明
                        </Typography>
                        <Button autoFocus color="inherit" onClick={()=>setIntroduce(false)}>
                        我已了解
                        </Button>
                    </Toolbar>
                    </AppBar>
                    <List>
                    <ListItem button>
                        <ListItemText primary="規則一" secondary="請先點選音檔再點擊箱子 配對成功即可得分" />
                    </ListItem>
                    <Divider />
                    <ListItem button>
                        <ListItemText
                        primary="規則二"
                        secondary="不論是音檔或是單字 以最後一個點擊的為主做配對"
                        />
                    </ListItem>
                    <ListItem button>
                        <ListItemText
                        primary="規則三"
                        secondary="玩家有三條命 三條命用光即重來 連續答對三次則combo  總分+30"
                        />
                    </ListItem>
                    <ListItem button>
                        <ListItemText
                        primary="規則四"
                        secondary="總秒數為15秒 時間到即失敗 答對一題+2秒"
                        />
                    </ListItem>
                    </List>
                </Dialog>                          
            </Box>
        ) :  isGameOver  ? (
            <Box sx={{width: '100%', height:`${windowDimensions.height}px`,backgroundColor:'white',position: 'relative'}}>
                {isMobile === true &&(
                    <GameOverView/>
                )}
                {isMobile === false &&(
                    <GameOverViewCom/>
                )}
                <Button 
                    variant="outlined"                  
                    style={{
                        position: 'absolute',  // 绝对定位
                        top: '55%',  // 从顶部偏移50%
                        left: '50%',  // 从左侧偏移50%
                        transform: 'translate(-50%, -50%)',  // 使用 transform 进行微调
                        zIndex: 2 , // 设置 z-index 以确保按钮出现在其他元素之上
                        fontSize:'24px',
                        color:'black',
                        border: '2px solid white',  // 设置边框
                        borderWidth: '2px',         // 设置边框宽度
                        borderColor: 'black',      // 设置边框颜色
                        }}
                    endIcon={<SportsMmaIcon style={{ fontSize: '40px',color:'black' }}/>}
                    onClick={() => {
                        setMobileGameStart(false);
                        setGameOver(false);
                        setIsAllPass(false);
                        setIsPass(false);
                        setLife(3);
                    }}
                >
                    Game Restart
                </Button>
            </Box>):
            !isGameOver && isAllPass ? (
            <Box sx={{width: '100%', height:`${windowDimensions.height}px`,backgroundColor:'white',position: 'relative'}}>
                <GameAllPassView/>
                <Button 
                    variant="outlined"                  
                    style={{
                        position: 'absolute',  // 绝对定位
                        top: '55%',  // 从顶部偏移50%
                        left: '50%',  // 从左侧偏移50%
                        transform: 'translate(-50%, -50%)',  // 使用 transform 进行微调
                        zIndex: 2 , // 设置 z-index 以确保按钮出现在其他元素之上
                        fontSize:'24px',
                        color:'black',
                        border: '2px solid white',  // 设置边框
                        borderWidth: '2px',         // 设置边框宽度
                        borderColor: 'black',      // 设置边框颜色
                        }}
                    endIcon={<SportsMmaIcon style={{ fontSize: '40px',color:'black' }}/>}
                    onClick={() => {
                        setMobileGameStart(false);
                        setGameOver(false);
                        setIsAllPass(false);
                        setIsPass(false);
                        setLife(3);
                    }}
                >
                    Game Restart
                </Button>
            </Box>):null}
    </>
  );
}

        // ) : 
        // isTablet ? (
        // <div>你正在使用平板设备。</div>
        // ) : (
        //     <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>      
        //     <Box sx={{
        //             width: '20%',
        //             height: '800px', 
        //         }}>
        //         <Box sx={{ 
        //             width: '20%',
        //             height: '200px', 
        //         }}/>
        //         <Box sx={{ 
        //             width: '100%',
        //             height: '100px', 
        //             display: 'flex',             // 使用 Flex 布局
        //             justifyContent: 'center',    // 在水平方向上居中
        //             alignItems: 'center',
        //         }}>
        //             <Button variant="contained" endIcon={<NotStartedIcon />} onClick={startGameClick}>
        //                 {show === true? 'start':'restart'}
        //             </Button>
        //         </Box>
        //         <Box sx={{ 
        //             width: '100%',
        //             height: '100px', 
        //             display: 'flex',             // 使用 Flex 布局
        //             justifyContent: 'center',    // 在水平方向上居中
        //             alignItems: 'center', 
        //             position: 'relative'
        //         }}>
        //             <img
        //                 ref={ballRef}
        //                 src='/images/ball.png'
        //                 alt='answer'
        //                 style={{position: 'absolute', top: `${initialBallPosition + ballYPosition}px`, width:70 , height:70}}
        //             />
        //             {answer && (
        //                 <span style={{
        //                     position: 'absolute',
        //                     left: '50%',
        //                     transform: 'translate(-50%, -50%)',
        //                     backgroundColor:'white',
        //                     zIndex: 1,
        //                     border: '2px solid black',
        //                     padding:'2px',    // 添加边框
        //                     borderRadius: '8px',
        //                     top: `${initialBallPosition + ballYPosition+30}px`, 
        //                 }}>
        //                     {answer}
        //                 </span>
        //             )}
        //         </Box>
        //         <Box sx={{ 
        //             width: '20%',
        //             height: '300px', 
        //         }}/>
        //         <Box sx={{ 
        //             width: '100%',
        //             height: '100px', 
        //             display: 'flex',             // 使用 Flex 布局
        //             justifyContent: 'center',    // 在水平方向上居中
        //             alignItems: 'center', 
        //             position: 'relative'
        //         }}>
        //             <img
        //                 ref={hoopRef}
        //                 src='/images/hoop.png'
        //                 alt='answer'
        //                 style={{ position: 'absolute', top: '0px',width:100 , height:100}}
        //             />
        //         </Box>
        //     </Box>
        //     <Box
        //         sx={{
        //             width: '80%',
        //             height: '800px',
        //             backgroundColor: 'white',
        //             borderLeft: '3px solid black',
        //             borderRight: '3px solid black',
        //             borderBottom: '3px solid black',
        //             borderRadius: '0 0 8px 8px',
        //             marginLeft: 'auto',
        //             backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${process.env.PUBLIC_URL}/images/gameBack.png)`,
        //             backgroundSize: 'cover',
        //             backgroundRepeat: 'no-repeat',
        //             backgroundPosition: 'center',
        //             flexGrow: 1,
        //             display: 'flex',
        //             flexDirection: 'column',
        //             justifyContent: 'space-between', 
        //             position: 'relative',
                    
        //         }}  
        //     > 
        //         { !show && (
        //             <img
        //                 src="/images/alarm.gif"
        //                 alt="your_alt_text_here"
        //                 style={{
        //                     position: 'absolute',
        //                     top: '50%',
        //                     left: '50%',
        //                     transform: 'translate(-50%, -50%)',
        //                     zIndex: 1  // 如果需要让图片显示在其他元素之上
        //                 }}
        //             />
        //         )}
        //                     { !show && (
        //                         <Alert severity="warning">作答時間已結束 Time Up</Alert>
        //         )}           
        //         <Grid container spacing={2}>
        //         {words.map((word, index) => {
        //             const imageName = imageNames[index % imageNames.length];
        //             const initPos = initialPositions[index];
        //             return (
        //                 show && (
        //                 <Grid item xs={1} key={index} sx={{marginRight:'1%'}}>
        //                     <animated.div
        //                         // eslint-disable-next-line no-return-assign
        //                         ref={(el) => tetrisRefs.current[index] = el}
        //                         style={{
        //                             width: 96,
        //                             height: 96,
        //                             borderRadius: 8,
        //                             ...springs1,
        //                             display: 'flex',
        //                             justifyContent: 'center',
        //                             alignItems: 'center',
        //                             textAlign: 'center',
        //                             backgroundImage: `url(${process.env.PUBLIC_URL}/images/${imageName}.png)`,
        //                             backgroundSize: 'cover',
        //                             backgroundRepeat: 'no-repeat',
        //                             backgroundPosition: 'center',
        //                         }}
        //                     >                                              
        //                                 <Button variant="contained" size="medium" style={{backgroundColor:'white',color:'black'}} onClick={()=>sendAnswer(word)}>
        //                                     {word}
        //                                 </Button>
        //                     </animated.div>
        //                 </Grid>)
        //             );
        //         })}                 
        //         </Grid>
                
        //         <div ref={containerRef} style={{ display: 'flex', justifyContent: 'space-around', alignSelf: 'flex-end',width:'100%' }}>
        //         {Array.from({ length: numImages }).map((_, index) => (
        //             <img
        //             ref={gifRef}
        //             key={index}
        //             src='/images/campfire.gif'
        //             alt={`fire`}
        //             style={{ width: 64, height: 64 }}
        //             />
        //         ))}
        //         </div>
        //     </Box>
        //     </Box>
        // )}