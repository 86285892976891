import React, { useState } from 'react';
import 'animate.css/animate.min.css';

function Combo() {
    return (
        <h1 className="animate__animated animate__bounce" 
                    style={{color:'red',  
                        width:'100%',
                        paddingLeft:'10px',
                        height:'auto',                      
                        // position: 'absolute',  // 绝对定位
                        // top: '25%',  // 从顶部偏移50%
                        // left: '32%',  // 从左侧偏移50%
                        // transform: 'translate(-50%, -50%)',  // 使用 transform 进行微调
                        // zIndex: 2 , // 设置 z-index 以确保按钮出现在其他元素之上
                        fontSize:'20px'}}>
          Combo
        </h1>
    ); 
}
export default Combo;